<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="printTitle.id" @deleteRecord="deletePrintTitle"></app-button-delete>
              <app-button-close route-name="printTitle_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <input type="hidden" v-model="printTitle.id">
                    <app-select
                      v-model="printTitle.site"
                      :options="sites"
                      id="printTitle_site"
                      :label="$t('printTitle.site')">
                    </app-select>

                    <app-input
                      v-model="printTitle.title"
                      @blur="$v.printTitle.title.$touch()"
                      :error="$v.printTitle.title.$error"
                      id="printTitle_title"
                      :label="$t('printTitle.title')"
                    >
                    </app-input>

                    <app-input
                      v-model="printTitle.name"
                      @blur="$v.printTitle.name.$touch()"
                      :error="printTitleNameShowError"
                      id="printTitle_name"
                      :label="$t('printTitle.name') + ' (' + $t('printTitle.name_validation') + ')'"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import { helpers, required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import PrintTitleModel from '../../model/PrintTitle'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'

const nameValidation = helpers.regex('nameValidation', /^[a-z\-0-9]+$/)

export default {
  name: 'PrintTitleNew',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      printTitle: this._.cloneDeep(PrintTitleModel),
      findNameTimeout: null,
      isNameValid: false,
      oldName: null
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/all']
    },
    printTitles () {
      return this.$store.getters['printTitle/list']
    },
    printTitleName () {
      return this.printTitle.name
    },
    printTitleNameShowError () {
      return this.$v.printTitle.name.$error || (this.$v.printTitle.name.$dirty && !this.isNameValid)
    }
  },
  watch: {
    printTitleName (newValue) {
      this.isNameValid = false
      if (this.findNameTimeout) {
        clearTimeout(this.findNameTimeout)
        this.findNameTimeout = null
      }
      this.findNameTimeout = setTimeout(() => {
        this.$store.dispatch('printTitle/fetchByName', newValue)
          .then(() => {
            this.isNameValid = false
            if (this.oldName === null) {
              // create
              if (this.$store.getters['printTitle/totalCount'] === 0) {
                this.isNameValid = true
              }
            } else {
              // update
              if (this.$store.getters['printTitle/totalCount'] === 0) {
                this.isNameValid = true
              } else if (this.$store.getters['printTitle/totalCount'] !== 0 && this.oldName === this.printTitle.name) {
                this.isNameValid = true
              }
            }
          })
      }, 500)
    }
  },
  validations: {
    printTitle: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      name: {
        required,
        nameValidation,
        minLength: minLength(1),
        maxLength: maxLength(45)
      },
      site: {
        minValue: minValue(1)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isNameValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('printTitle/create', this.printTitle)
          .then(() => {
            if (this.$store.getters['printTitle/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.printTitle = this.$store.getters['printTitle/detail']
              this.$router.push('/printTitle/' + this.printTitle.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['printTitle/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deletePrintTitle () {
      this.$store.dispatch('printTitle/deleteRecord', this.printTitle)
        .then(() => {
          if (this.$store.getters['printTitle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/printTitle')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printTitle/error'])
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
